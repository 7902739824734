import { TableColumnType } from "../../../components/DataTable/interfaces";
import { authUserProps, queryDataProps } from "src/interfaces";
import moment from "moment";
import EmergencyIcon from "../EmergencyIcon";
import { isQueryClosedAndCancelled } from "../helpers";
import { dateParser, getTableColorFromStatus } from "src/helpers";

export const tableFields = (
  onQueryClick: Function,
  onCustomerClick: Function,
  authUser?: authUserProps
): TableColumnType<any>[] => [
  {
    title: "Query #",
    field: "id",
    cell: (row: queryDataProps) => {
      return (
        <div
          className="flex items-center text-[#075985] cursor-pointer"
          onClick={() => onQueryClick(row)}
        >
          {row?.query_no}{" "}
          {row?.is_emergency == 1 && (
            <EmergencyIcon className="absolute left-[5.5rem]" />
          )}
        </div>
      );
    },
  },
  {
    title: "Customer",
    field: "name",
    cell: (row: queryDataProps) => {
      return (
        <div
          className="flex flex-col text-[#075985] cursor-pointer"
          onClick={() => onCustomerClick(row)}
        >
          <div className="text-base font-medium whitespace-nowrap">
            {row?.customer?.fullname}
          </div>
          <div className="mt-1 text-xs">{row.customer?.customer_id || ""}</div>
        </div>
      );
    },
  },
  {
    title: "No. of PAX",
    field: "pax",
    cell: (row: queryDataProps) => {
      return row?.no_of_adults + row?.no_of_children + row?.no_of_infants;
    },
  },
  {
    title: "Created By",
    field: "createdBy",
    cell: (row: queryDataProps) => {
      return row?.sourced_by == authUser?.id
        ? "You"
        : `${row?.sourced_by_user?.name} ${
            row?.sourced_by_user?.last_name
              ? row?.sourced_by_user?.last_name
              : ""
          }`;
    },
  },
  {
    title: "Assigned To",
    field: "assignedTo",
    cell: (row: queryDataProps) => {
      if (!row?.assigned_to_user?.name && !row?.assigned_to_user?.last_name) {
        return "N/A";
      }
      return (
        (row?.assigned_to_user?.name || "") +
        " " +
        (row?.assigned_to_user?.last_name
          ? row?.assigned_to_user?.last_name
          : "")
      );
    },
  },
  {
    title: "Created At",
    field: "time",
    cell: (row: queryDataProps) => {
      return dateParser(row?.created_at, "DD-MM-YYYY");
    },
  },
  {
    title: "Estimated Travel Date",
    field: "travel_date",
    cell: (row: queryDataProps) => {
      return row?.travel_date ? dateParser(row?.travel_date, "DD-MM-YYYY") : "";
    },
  },
  {
    title: "Current Status",
    field: "status",
    cell: (row: queryDataProps) => {
      return (
        <>
          {isQueryClosedAndCancelled(row.status) ? (
            <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#F5CECC] rounded-md text-[#CE3B3B]">
              {row?.status_text}
            </span>
          ) : (
            <span className="w-16 p-1 text-start text-xs border border-[rgba(48, 48, 48, 0.1)] bg-[#DFF8ED] rounded-md text-[#44833A]">
              {row?.status_text}
            </span>
          )}
        </>
      );
    },
  },
];

interface TripType {
  label: string;
  val: number;
}

export const tripTypes: Array<TripType> = [
  { label: "Return", val: 1 },
  { label: "One way", val: 2 },
];

export const queryTypes: Array<{ label: string; val: number }> = [
  { label: "New Ticket: International", val: 1 },
  { label: "New Ticket: Domestic", val: 2 },
];

export const countries: Array<{ label: string; val: string }> = [
  { label: "India", val: "India" },
  { label: "Canada", val: "Canada" },
  { label: "Australia", val: "Australia" },
  { label: "Japan", val: "Japan" },
];

export const queryTypesEnum = {
  1: "New Ticket: International",
  2: "New Ticket: Domestic",
};

export const queryActionStatusEnum = {
  1: "Assigned",
  2: "Assigned for Payment",
  3: "Request for update",
};

export interface Source {
  label: string;
  val: string;
}

export const sources: Array<Source> = [
  {
    label: "Website",
    val: "Website",
  },
  {
    label: "Phone Call",
    val: "Phone",
  },
  {
    label: "Walk-In",
    val: "Walk-in",
  },
];
